
const ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/3939523/bk7uuq7/'

//parallax
const prx = document.getElementsByClassName('prx');
new simpleParallax(prx, {
	overflow: true
});

// scroll btn
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});